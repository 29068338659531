<template>
  <div>
    <v-container fill-height fluid grid-list-xl>
      <v-layout
        row
        wrap xs12>
        <v-flex xs12 sm6 md3>
          <v-text-field 
            label="UUID/Hex number карты" 
            v-model="cardId" 
            class="shrink"
            type="text"></v-text-field>
        </v-flex>
        <v-flex xs12 sm8 md4>
          <v-btn color="info" @click="searchbyId">Поиск по UUID</v-btn>
          <v-btn color="info" @click="searchbyNumber">Поиск по Hex</v-btn>
          <v-btn color="info" @click="clean">Очистить</v-btn>
        </v-flex>
      </v-layout>
    </v-container>

    <v-container
      fill-height
      fluid
      grid-list-xl
    >
      <v-layout
        justify-center
        wrap
      >
        <v-flex
          md12
        >
          <v-flex
            xs12 sm12 md12 lg8
          >
          <material-card
            color="green"
            title="Карта"
            
          >
            <v-list dense v-if="card">
              <v-list-tile>
                <v-list-tile-content>Id:</v-list-tile-content>
                <v-list-tile-content class="align-end">{{ card.id }}</v-list-tile-content>
              </v-list-tile>
              <v-list-tile>
                <v-list-tile-content>Тип:</v-list-tile-content>
                <v-list-tile-content class="align-end">{{ card.type }}</v-list-tile-content>
              </v-list-tile>
              <v-list-tile>
                <v-list-tile-content>Hex number:</v-list-tile-content>
                <v-list-tile-content class="align-end">{{ card.number }}</v-list-tile-content>
              </v-list-tile>
              <v-list-tile>
                <v-list-tile-content>User Id:</v-list-tile-content>
                <v-list-tile-content class="align-end">{{ card.userId }}</v-list-tile-content>
              </v-list-tile>
              <v-list-tile>
                <v-list-tile-content>Добавлена:</v-list-tile-content>
                <v-list-tile-content class="align-end">{{ card.createdOn | date }} {{ card.createdOn | time }}</v-list-tile-content>
              </v-list-tile>
              <v-list-tile>
                <v-list-tile-content>Изменена:</v-list-tile-content>
                <v-list-tile-content class="align-end">{{ card.modifiedOn | date }} {{ card.modifiedOn | time }}</v-list-tile-content>
              </v-list-tile>
            </v-list>
            <p v-if="error">{{ error }}</p>
          </material-card>
          </v-flex>
        </v-flex>
      </v-layout>
    </v-container>

    
  </div>
</template>

<script>
export default {
  data: () => ({
    valid: false,
    loading: false,
    cardId: null,
    error: null,
    card: null
  }),
  mounted () {

  },
  methods: {
    resetBeforeSearch(){
      this.card = null;
      this.error = null;
    },
    searchbyId(){
      this.resetBeforeSearch();
      this.getCard(this.cardId)
    },
    searchbyNumber(){
      this.resetBeforeSearch();
      this.getCardByNumber(this.cardId)
    },
    clean(){
      this.cardId = null;
      this.resetBeforeSearch();
    },
    getCard(id) {
      this.$http.get('/cards/' + id)
        .then(response => {
          this.card = response.data
        })
        .catch(error => {
          console.log(error.response)
          if (error.response){
            if (error.response.status === 404){
              this.error = error.response.data.title;
            }
            else if (error.response.status === 400) {
              this.error = error.response.data;
            }
            else{
              this.error = "Ошибка"
            }
          }
          else{
            this.error = "Ошибка"
          }
        })
    },
    getCardByNumber(number) {
      this.$http.get('/cards/number/' + number)
        .then(response => {
          this.card = response.data
        })
        .catch(error => {
          if (error.response){
            if (error.response.status === 404){
              this.error = error.response.data.title;
            }
            else if (error.response.status === 400) {
              this.error = error.response.data;
            }
            else{
              this.error = "Ошибка"
            }
          }
          else{
            this.error = "Ошибка"
          }
        })
    }
  }
}
</script>
